import React, { useState, useRef } from "react";
import useSWR from "swr";
import { Link, useParams } from "react-router-dom";

import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

import SuperAdminLayout from "../../layouts/SuperAdminLayout";
import BillboardTable from "./BillboardTable";
import VideoFileDetails from "./VideoFileDetails";
import VideoCoordinatesTable from "./VideoCoordiatesTable";
import AddToPlan from "../../components/AddToPlan";

import { getProcessedOutputAPI, revertBillboardsAPI } from "../../apis/videos.apis";
import roles from "../../constants/roles";
import useAuth from "../../hooks/useAuth";
import { Stack } from "@mui/material";
import { toast } from "react-toastify";


const ProcessedOutput = () => {
	const { video_id } = useParams();
	const [isDiscarding, setIsDiscarding] = useState();

	const [assetInfoState, setAssetInfoState] = useState({
		isOpen: false,
		assetId: null,
		coords: [],
	});

	const initialBillboardRef = useRef();

	const { user_id, role_id } = useAuth();

	const { data, isLoading, mutate } = useSWR("/videos/output/" + video_id, () =>
		getProcessedOutputAPI(video_id)
	);

	if (data?.billboards && !initialBillboardRef.current) {
		initialBillboardRef.current = [...data.billboards]
	}

	const isAuthorizedForActions =
		role_id === roles.SUPERADMIN ||
		data?.video_details?.created_by_user_id === user_id;

	const handleMerge = () => {
		mutate();
	};

	const discardBillboardChanges = async () => {
		try {

			if (
				!window.confirm(
					"Are you sure you want to revert?"
				)
			) {
				return;
			}


			setIsDiscarding(true)
			await revertBillboardsAPI(initialBillboardRef.current)
			toast.success("Changes reverted successfully!");
			mutate();
		} catch (error) {
			toast.error("Something went wrong!");
		}finally{
			setIsDiscarding(false)
		}
	};

	const handleAssetClose = () => {
		setAssetInfoState({
			isOpen: false,
			assetId: null,
			coords: [],
			avgSpeed: 0,
		});

		mutate();
	};

	const openAssetInfo = (row) => {
		setAssetInfoState({
			isOpen: true,
			assetId: row.id,
			coords: data.video_coordinates?.map((v) => [v.latitude, v.longitude]),
			row,
		});
	};

	return (
		<SuperAdminLayout activeLink={"/add-video"}>
			{isLoading || isDiscarding ? (
				<Typography>Loading...</Typography>
			) : !data || !data?.video_details ? (
				<center>
					<Typography>No Data Found!</Typography>
				</center>
			) : null}

			{data?.video_details ? (
				<>
					<Typography variant="h6" mb={1}>
						Video Details
					</Typography>
					<VideoFileDetails data={data.video_details} />

					<Stack alignItems={"center"} gap={4} direction={"row"}>
					<Typography my={2} variant="h6" mb={1}>
						Detected OOH Assets
					</Typography>
					<Button onClick={discardBillboardChanges} size="small" color="error" variant="contained" disableElevation>Discard Changes</Button>
					</Stack>
					<BillboardTable
						data={data.billboards || []}
						onMerge={handleMerge}
						videoId={data?.video_details?.video_id}
						isAuthorized={isAuthorizedForActions}
						onAddAssetInfo={openAssetInfo}
						onMergeSuccess={mutate}
					/>

					<Typography my={2} variant="h6" mb={1}>
						Detected Coordinates
					</Typography>
					<VideoCoordinatesTable
						videoCoordinates={data.video_coordinates}
						avgSpeed={data.avg_speed_km}
						stretchedDistance={data.stretched_in_meters}
					/>

					<Box mt={4}>
						<Button LinkComponent={Link} variant="contained" to="/add-video">
							Done
						</Button>
					</Box>
				</>
			) : null}

			{assetInfoState?.assetId ? (
				<AddToPlan
					assetId={assetInfoState.assetId}
					open={assetInfoState.isOpen}
					onClose={handleAssetClose}
					initialCoords={assetInfoState.coords}
					row={assetInfoState.row}
				/>
			) : null}
		</SuperAdminLayout>
	);
};

export default ProcessedOutput;
